.titleCard {
  background: #FFFFFF;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 29.4316px;
  width: 380px;
  height: 215px;
  padding: 24px 0;
  text-align: center;
}

.awaitingListing {
  color: #052137;
  font-family: Segoe UI;
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
}

.awaitingListingCount {
  color: #C8202D;
  font-family: Segoe UI;
  font-size: 56px;
  font-weight: 700;
  line-height: 74px;
  letter-spacing: 0em;

}

.awaitingListTable {
  width: 100%;
  border-collapse: collapse;
}

.awaitingListTable th {
  border-bottom: solid 1px #052137;
  padding: 20px 10px;
}

.awaitingListTable td {
  text-align: center;
  padding: 18px 0;
}

.profileDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgDiv img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.idDiv {
  display: flex;
  justify-content: center;
}

.h3Div {
  width: 50%;
  height: auto;
  background: #FFF;
  box-shadow: 0px 4px 31px -4px rgba(0, 0, 0, 0.25);
  border-radius: 10.28px;
  display: flex;
  justify-content: space-evenly;
  margin: 1% 0;
}

.h3Div h3 {
  padding: 8%;
}

.fileDiv .file {
  font-size: 100px;
  color: rgb(0, 119, 255);;
  display: flex;
  justify-content: space-evenly;
  margin: 12px 0;
}

.fileDiv .statement {
  font-size: 20px;
  display: flex;
  justify-content: space-evenly;
  margin: 12px 0;
}

.verifyBtn {
  margin: 2% 0 5% 0;
  display: flex;
  justify-content: center;
}

.verifyBtn button {
  background-color: rgb(0, 119, 255);
  color: #fff;
  border: none;
  margin: 0 1%;
  padding: 1% 2%;
  font-weight: 600;
  border-radius: 5px;
}