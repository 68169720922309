@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

a {
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  color: #80000065;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888886;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5555557a;
}

button {
  border: none;
}

.position-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-toggle {
  background: white;
  box-shadow: 1px 1px 7px #999;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 7px 1.5rem;
  border-radius: 1rem;
  gap: 2rem;
}

/* .position-center {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.css-1monzuk {
  height: max-content !important;
}

.capitalise {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

.css-v9t4b8-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #0c4775 !important;
}

.css-1wnsr1i {
  width: 50% !important;
}
.modal-parent {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-confirm {
  text-align: center;
  background-color: white !important;
  z-index: 99;
  padding: 2rem;
  border-radius: 7px;
}

.renewal-container {
  margin-top: 5rem;
}
.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.renewal-summary {
  padding: 2rem;
  box-shadow: 1px 1px 1rem rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  border-radius: 1rem;
}
.summary-item {
  margin-bottom: 10px;
}
.action-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.status-tag {
  background-color: rgba(0, 128, 0, 0.692);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  margin-left: 1rem;
}
